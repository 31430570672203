<template>
    <DataTable :value="users" filterDisplay="menu" :sort-field="'lastExecutionDateUtc'" :sort-order="-1">
        <Column field="userId" header="User Id" :sortable="true">
        </Column>
        <Column field="name" header="Name" :sortable="true">
        </Column>
        <Column field="email" header="Email" :sortable="true">
        </Column>
        <Column field="selectedProfileId" header="Selected Profile" :sortable="true">
        </Column>
        <Column header="Actions">
            <template #body="slotProps">
                <Button :loading="isLoading" label="Login" @click="impersonateUser(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</template>

<script lang="ts" setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";

</script>

<script lang="ts">
import type { UserRecord } from "@/models/admin";
import { defineComponent } from "vue";
import adminService from "@/services/admin.service";
import userService from "@/services/user.service";

export default defineComponent({
    data() {
        return {
            users: [] as UserRecord[],
            isLoading: false
        };
    },
    async created() {
        this.users = await adminService.getUsers();
    },
    methods: {
        async impersonateUser(userRecord: UserRecord) {
            this.isLoading = true;
            try {
                // When impersonating start out in readonly mode.
                await adminService.impersonateUser(userRecord.email, false);
                this.$router.push({ name: 'Home' });
            } finally {
                this.isLoading = false;
            }
        },
    }
});
</script>
